.boxcontainer {
    display: flex;
    /*outline: 5px solid yellow;*/
    width: 100%;
    height:95%;
}

.box {
    display: block;
    padding-left: 5px;
    width: calc(100vw / 2);
    height:95%;
}

.box:first-child {
    width: 25%;
}

.box:last-child {
    width: 75%;
}

.channelActions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tooltipContainer {
    position: relative;
    display: inline-block;
}

.addIcon {
    cursor: pointer;
    color: var(--icon-color);
    transition: color 0.3s ease;
}

.addIcon:hover {
    color: var(--green-light-color)
}