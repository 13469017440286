
/* css to make the spinner image and animation*/
.loader {
    width: 40px;
    height: 40px;
    border: 4px solid var(--color-white);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* image__wrapper is frame around the spinner itself */
.image__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background: #666666;
    opacity: 0.8;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Css to cover whole parent div so we easily can center the loading spinner */
.cover__parent {
    width: 100%;
    height: 100%;
    position: relative;
}

.blurry{
    filter: blur(5px);
}