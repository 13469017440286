.boxcontainer {
    display: flex;
    width: 100%;
    height: 98vh; /* Use viewport height units */
    max-height: 100%; /* Ensure it doesn't exceed the parent container */
    overflow: hidden; /* Prevent any overflow */
}

.box {
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    height: 100%;
}

.leftBox {
    width: 25%;
    overflow: hidden; /* Prevent scrolling on the left box itself */
}

.inputContainer {
    flex-shrink: 0; /* Prevent this container from shrinking */
}

.channelsListContainer {
    flex: 1; /* This will make it fill the remaining space */
    min-height: 0; /* This is crucial for enabling flex item to shrink below its content size */
    overflow-y: auto; /* Enable vertical scrolling for this container */
    display: flex; /* Make this a flex container */
    flex-direction: column; /* Stack its children vertically */
}

.rightBox {
    width: 75%;
}

.channelActions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.removeIcon {
    cursor: pointer;
    color: var(--icon-color);
    transition: color 0.3s ease;
}

.removeIcon:hover {
    color: var(--danger-color)
}

.tooltipContainer {
    position: relative;
    display: inline-block;
}