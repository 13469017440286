/* Used on div put around input field */
.button__wrapper {
  margin: 0px 10px;
  padding: 20px 0px;
  position: relative;
}

/* Buttons */

.button {
  background: var(--button-background-color);
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 26px;
  border: 1px solid var(--button-border-color);
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  color: var(--button-color);
  box-shadow: 0px 2px 2px var(--button-shadow-color);
  cursor: pointer;
  transition: .2s;
  width: fit-content;
}

.button:active,
.button:focus,
.button:hover {
  border-color: var(--button-hover-border-color);
  outline: none;
}

.button__icon {
  padding-left: 3px;
  /*font-size: 24px;*/
  margin-left: auto;
  color: var(--button-icon-color)
}

.button__text {

}