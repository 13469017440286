.formContainer {
    max-height: fit-content;
    overflow: hidden;
    padding: 10px;
    box-sizing: border-box;
}

.formstyling {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.formstyling input {
    flex: 1;
}

/* Add this media query for responsiveness */
@media (max-width: 600px) {
    .formstyling {
        flex-wrap: wrap;
    }

    .formstyling input {
        max-width: none;
    }
}