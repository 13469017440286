.viewport {
    height: 100vh;
    width: 100vw;
}

.appContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.mainContent {
    flex: 1;
    overflow-y: auto;
    background: var(--secondary-background);
}