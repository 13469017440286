.viewport {
  /*outline: 5px solid green;*/
  height:95vh;
}


/* ************ Small helper styling classes ************ */
.align_horizontally {
  justify-content: center;
  display: flex;
  text-align: center;
  vertical-align: middle;
}

.fit-content {
  width: fit-content;
}