.collapsible .content {
    padding: 6px;
    background: var(--main-background)
}

.collapsible .header {
    background: var(--header-background);
    padding: 6px;
    cursor: pointer;
}

.headerText {
    color: #a1a0a0;
    text-decoration: none;
}