.container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: var(--main-background)
}

.screen__content {
    z-index: 1;
    position: relative;
    height: 100%;
}