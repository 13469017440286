/* ************ Input fields ************  */

/* Used on div put around input field */
.input__wrapper {
    padding: 5px 0px;
    position: relative;
}

input {
    border: none;
    border-bottom: 2px solid #D1D1D4;
    background: none;
    padding: 10px 20px;
    font-weight: 700;
    width: 75%;
    transition: .2s;
}

input:active,
input:focus,
input:hover {
    outline: none;
    border-bottom-color: #6A679E;
}

/* Icon put on input field*/
input i {
    position: absolute;
    top: 30px;
    color: #7875B5;
}