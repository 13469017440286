
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Raleway, sans-serif;
}

.delete_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
}

.user_info {
    display: flex;
    flex-direction: column;
}

.screen__content {
    z-index: 1;
    position: relative;
    height: 100%;
}

.login {
    width: 320px;
    padding: 30px;
    padding-top: 20px;
}

.login__field {
    padding: 20px 0px;
    position: relative;
}