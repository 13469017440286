:root {
    /* Colors */
    /*--primary-color: #4C489D;*/
    /*--secondary-color: #7875B5;*/
    /*--background-color: #F0F0F0;*/
    /*--text-color: #333333;*/
    /*--link-color: #0000EE;*/

    /* Different colors used in application */
    --color-white: #ffffff;
    --hover-color-with-main-background: #f5f5f5;
    --danger-color: #ff4d4d;
    --green-light-color: #4CAF50;
    --icon-color: #888;
    --resize-border-color: #007bff;
    --resize-background-color: #007BFF19;

    /* Button colors */
    --button-background-color: #fff;
    --button-border-color: #D4D3E8;
    --button-color: #4C489D;
    --button-shadow-color: #5C5696;
    --button-hover-border-color: #6A679E;
    --button-icon-color: #7875B5;

    /* Menu colors*/
    --menu-item-color: #a1a0a0;
    --menu-item-hover-color: #cccccc;
    --menu-item-active-color: white;

    /* Background */
    --main-background: linear-gradient(90deg, #e5e5ec, #b4b3bc);
    --secondary-background: #b4b3bc;
    --header-background: linear-gradient(to bottom, #555559, #696c70);

    /* You can add more variables as needed */
}