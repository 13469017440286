.preview {
    margin:0;
    width:500px;
    height:250px;
    overflow: hidden;
}

.preview video {
    width: 500px !important;
    height: 250px !important;
}

.videoContainer {
    position: relative;
    display: inline-block;
}

.alignRight {
    float: right;
}

.alignLeft {
    float: left;
}

.resizeHandleCorner {
    bottom: -5px;
    right: -5px;
    width: 10px;
    height: 10px;
    cursor: nwse-resize;
}

.resizeFrame {
    position: fixed;
    border: 2px dashed var(--resize-border-color);
    pointer-events: none;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    background-color: var(--resize-background-color);
}

.resizeIcon {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 14px;
    color: var(--icon-color);
    opacity: 0.7;
    transition: opacity 0.2s;
}

.resizeHandleCorner:hover .resizeIcon {
    color: var(--color-white);
    opacity: 1;
}