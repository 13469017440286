.header {
  background: var(--header-background);
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: calc(10px + 1vmin);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logoContainer {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.logo {
  color: var(--button-icon-color);
  height: 70%;
  display: flex;
}

.menu {
  flex: 1; /* Allow menu to take up remaining space */
  display: flex;
  justify-content: center; /* Center the menu items */
  padding: 0;
  margin: 0;
}

.menu ul  {
  list-style-type: none;
  display: flex;
}

.menu li {
  margin: 0 15px;
}

.menu a {
  color: var(--menu-item-color);
  text-decoration: none;
  /*font-size: calc(10px + 1vmin);*/
}

.menu a:hover:not(.active) {
  color: var(--menu-item-hover-color);
}

.activeLink {
  color: var(--menu-item-active-color) !important;
}