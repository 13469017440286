.loading {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.List {
    border: 1px solid var(--hover-color-with-main-background);
    border-radius: 0.25rem;
    height: 100%; /* Ensure the list takes full height */
    overflow-y: auto; /* Allow vertical scrolling */
}

.ListItem {
    padding: 0rem 1rem;
    line-height: 30px;
}

.listitem__wrapper {
    margin: 50px 0px;
    padding: 20px 0px;
}

.channel_item_container {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid var(--hover-color-with-main-background);
    transition: background-color 0.2s ease;
}

.channel_item_container:hover {
    background-color: var(--hover-color-with-main-background);
}

.firstCol {
    flex: 0 0 40px;
    font-weight: bold;
    color: #666;
    text-align: center;
    cursor: pointer;
    border-right: 1px solid #e0e0e0; /* Add this line for the vertical separator */
    margin-right: 10px; /* Add some space after the separator */
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    cursor: pointer;
}

.name {
    font-weight: 500;
    font-size: 0.9em;
    color: #333;
}

.location {
    font-size: 0.6em;
    color: #777;
    margin-top: 2px;
}

.list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.screen__content {
    z-index: 1;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}
